import ToggleElement from './toggle-element';

export default class NavMenu {
	el: HTMLElement;
	groups: NavMenuGroup[];

	constructor(el: HTMLElement) {
		this.el = el;

		const groupElements = Array.from(
			this.el.querySelectorAll<HTMLElement>('[data-nm-group]')
		);

		const groups = groupElements
			.map((group) => {
				const button = group.querySelector<HTMLElement>('[data-nm-button]');
				const controls = group.querySelector<HTMLElement>('[data-nm-menu]');

				if (!button || !controls) return;

				return new NavMenuGroup(group, this, {
					button,
					controls,
				});
			})
			.filter(
				(navMenuGoup): navMenuGoup is NavMenuGroup =>
					navMenuGoup instanceof NavMenuGroup
			);

		this.groups = groups;
	}
}

class NavMenuGroup extends ToggleElement {
	expandedClassName = 'nav-menu__group--open';
	group: HTMLElement;
	navMenu: NavMenu;

	constructor(
		group: HTMLElement,
		navMenu: NavMenu,
		...opts: ConstructorParameters<typeof ToggleElement>
	) {
		super(...opts);

		this.group = group;
		this.navMenu = navMenu;
	}

	changedState = (expanded: boolean) => {
		if (expanded) {
			this.navMenu.groups
				.filter((group) => group !== this)
				.forEach((group) => {
					group.contract();
				});
		}
		this.group.classList.toggle(this.expandedClassName, expanded);
	};
}
