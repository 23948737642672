export default class LoadPosts {
	postNumber = 0;
	totalNumberOfPosts = 0;

	constructor(el) {
		this.el = el;
		this.loadFrom = el.getAttribute('data-load-posts-from');
		this.loadInto = el.getAttribute('data-load-posts-into');
		this.loadIntoEl = document.querySelector(this.loadInto);
		this.showNumberOfPosts = document.querySelector('[data-show-post-number]');
		this.showNumberOfPosts.innerText = this.loadIntoEl.children.length;

		if (!this.loadFrom || !this.loadInto || !this.loadIntoEl) {
			return;
		}

		this.postNumber = this.loadIntoEl.children?.length || 0;

		this.el.addEventListener('click', this.handleClick);
	}

	appendPost = (post) => {
		const newPost = this.loadIntoEl.children[0].cloneNode(true);
		const title = newPost.querySelector('[data-title]');
		const image = newPost.querySelector('[data-featured-image]');
		const excerpt = newPost.querySelector('[data-excerpt]');
		const permalinks = newPost.querySelectorAll('[data-permalink]');
		const date = newPost.querySelector('[data-date]');

		if (title) {
			title.innerHTML = post.title ? post.title.rendered : '';
		}

		if (permalinks.length !== 0 && post.link) {
			permalinks.forEach((permaLink) => {
				permaLink.setAttribute('href', post.link);
			});
		}

		if (date) {
			if (post.date) {
				// Exclude time from date and add to element
				date.innerHTML = post.date.slice(0, post.date.indexOf('T'));
			} else {
				date.remove();
			}
		}

		if (excerpt) {
			excerpt.innerHTML = post.excerpt ? post.excerpt.rendered : '';
		}

		if (image) {
			if (post.os_featured_image_url) {
				image.setAttribute('src', post.os_featured_image_url);
				image.removeAttribute('srcset');
			} else {
				image.remove();
			}
		}

		this.loadIntoEl.appendChild(newPost);
	};

	handleClick = async () => {
		const sign = this.loadFrom.includes('?') ? '&' : '?';
		const url = `${this.loadFrom}${sign}offset=${this.postNumber}`;

		try {
			const response = await fetch(url);

			this.totalNumberOfPosts = Number(response.headers.get('X-WP-Total'));

			const posts = await response.json();
			this.postNumber += posts.length;
			this.showNumberOfPosts.innerText = this.postNumber;

			// Hide View more-button if all of the posts have been displayed
			if (this.postNumber >= this.totalNumberOfPosts) {
				this.el.remove();
			}

			posts.forEach((post) => {
				this.appendPost(post);
			});
		} catch (error) {
			console.error(error);
		}
	};
}
